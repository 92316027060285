<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Projekt-GP
          </h1>
          <h2 class="subtitle">
            GP / GC Eesti aarete ühine pott
          </h2>
        </div>
      </div>
    </section>
    <section v-if="gp && gc">
      <div class="container">
        <div class="tile is-ancestor">
          <div class="tile is-vertical">
            <div class="tile">
              <div class="tile is-parent">
                <article class="tile is-child notification is-success">
                  <p class="subtitle has-text-centered my-5">
                    <a href="https://geopeitus.ee" target="_blank">Geopeitus</a>
                  </p>
                  <p class="title has-text-centered my-5">{{ gp }}</p>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child notification is-info">
                  <p class="subtitle has-text-centered my-5">
                    <a href="https://geocaching.com" target="_blank">Geocacing</a>
                  </p>
                  <p class="title has-text-centered my-5">{{ gc }}</p>
                </article>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  </div>

</template>

<script>

import apiService from '@/services/ApiService'
import { error } from '@/utils'

export default {
  name: 'Home',
  data() {
    return {
      disabled: true,
      gp: null,
      gc: null
    }
  },
  activated() {
    let data = JSON.parse(localStorage.getItem('user'))
    const providers = []
    if (data && data['gp']) {
      providers.push('gp')
    }
    if (data && data['gc']) {
      providers.push('gc')
    }
    this.disabled = providers.length !== 2
    if (!this.disabled) {
      apiService.loadCachesStatistics(true)
        .then(response => {
          if (response.ok) {
            this.gp = response.data.active.gp + response.data.disabled.gp
            this.gc = response.data.active.gc + response.data.disabled.gc
          }
        })
        .catch(error)
    }
  }
}
</script>

<style scoped>

article .title {
  font-size: 6rem
}

article a {
  text-decoration: none!important
}
</style>
