import Vue from 'vue'

function queryParams(params) {
  let query = ''
  const status = Object.keys(params).filter(key => params[key])
  if (status.length) {
    query = '?' + new URLSearchParams({ filter: JSON.stringify({ status })}).toString()
  }
  return query
}

function request(url) {
  return Vue.prototype.$http
    .get(url)
    .then(response => {
      if (response.data.ok && response.data.data) {
        return response.data
      }
      throw Error('Data load failed')
    })
}

class ApiService {
  loadConfig() {
    return request(`/config`)
  }
  loadCachesStatistics(fromDB = false) {
    return request(fromDB ? `/statistics/caches/load` : `/statistics/caches/compare`)
  }
  loadCaches(provider, params) {
    return request(`/caches/${ provider ? provider : '' }${queryParams(params)}`)
  }
  loadCachesMissingList(params) {
    return request(`/statistics/caches/compare/missing${queryParams(params)}`)
  }
  loadCachesCommonList() {
    return request(`/statistics/caches/compare/common`)
  }
  loadUserFoundLogs(provider) {
    return request(`/logs/${provider}/found`)
  }
  loadLogsStatistics() {
    return request(`/statistics/logs/compare`)
  }
  loadLogsMissingList() {
    return request(`/statistics/logs/compare/missing`)
  }
  loadLogsCommonList() {
    return request(`/statistics/logs/compare/common`)
  }
}

export default new ApiService()
