import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import router from './router.js'
import axios from 'axios'
import VueEvents from 'vue-events'
import { config } from '@/config'
import 'buefy/dist/buefy.css'

Vue.prototype.$config = config

const http = axios.create({
  baseURL: config.api.url
});

Vue.use(VueEvents)

let user = JSON.parse(localStorage.getItem('user'))

if (user && user.token) {
  http.defaults.headers.common['Authorization'] = 'Bearer ' + user.token
}

Vue.prototype.$http = http

Vue.use(Buefy)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
