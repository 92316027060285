export const config = {
  version: process.env.VUE_APP_VERSION,
  api: {
    url: process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION,
    doc: process.env.VUE_APP_API_URL + '/apidocs'
  },
  map: {
    url: 'https://gp.sookoll.ee/#view=${lat}/${lon}/15/0/osm&icon=${lat}/${lon}'
  },
  providers: {
    gp: 'geopeitus.ee',
    gc: 'geocaching.com'
  },
  public: ['/', '/user/auth']
}
