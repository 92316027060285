import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import NotFoundComponent from './views/404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('./views/About.vue')
  },
  {
    path: '/user/auth',
    name: 'Authentication',
    component: () => import('./views/user/Auth.vue')
  },
  {
    path: '/caches/stats',
    name: 'CachesStatistics',
    component: () => import('./views/caches/Statistics.vue')
  },
  {
    path: '/caches/list',
    name: 'CachesList',
    component: () => import('./views/caches/List.vue')
  },
  {
    path: '/caches/list/missing',
    name: 'MissingList',
    component: () => import('./views/caches/MissingList.vue')
  },
  {
    path: '/caches/list/common',
    name: 'CommonList',
    component: () => import('./views/caches/CommonList.vue')
  },
  {
    path: '/logs/stats',
    name: 'LogsStatistics',
    component: () => import('./views/logs/Statistics.vue')
  },
  {
    path: '/logs/list',
    name: 'LogsList',
    component: () => import('./views/logs/List.vue')
  },
  {
    path: '/logs/list/missing',
    name: 'MissingLogsList',
    component: () => import('./views/logs/MissingList.vue')
  },
  {
    path: '/logs/list/common',
    name: 'CommonLogsList',
    component: () => import('./views/logs/CommonList.vue')
  },
  { path: '*', component: NotFoundComponent }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/',
    '/user/auth'
  ]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = !!localStorage.getItem('user')
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    if (from.path !== '/user/auth') {
      next('/user/auth')
    }
  } else {
    next()
  }
})

export default router
