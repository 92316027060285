import Vue from 'vue'
import { format as fnsFormat } from 'date-fns'

export const error = (err) => {
  const message = err.response && err.response.data.message ? err.response.data.message : err.message
  Vue.prototype.$buefy.snackbar.open({
    duration: 5000,
    message: `Viga: ${message}`,
    type: 'is-danger',
    position: 'is-top'
  })
}

export const templator = (template, obj) => {
  const keys = Object.keys(obj)
  const func = Function(...keys, "return `" + template + "`;")
  return func(...keys.map(k => obj[k]))
}

export const formatDate = (date, format = 'yyyy-MM-dd') => {
  return fnsFormat(new Date(date), format)
}
