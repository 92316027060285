import Vue from 'vue'

class AuthService {
  login(user) {
    return Vue.prototype.$http
      .post(`/user/authenticate/${user.provider}`, {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.ok && response.data.token) {
          let data = JSON.parse(localStorage.getItem('user'))
          if (data && data.token && data.token === response.data.token) {
            data[user.provider] = user.username
          } else {
            data = {
              token: response.data.token
            }
            data[user.provider] = user.username
          }
          Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + data.token
          localStorage.setItem('user', JSON.stringify(data))
        }
        return response.data
      })
  }
  logout() {
    return Vue.prototype.$http
      .get(`/user/logout`)
      .then(response => {
        return response.data
      })
      .finally(() => {
        // log out regardless of server response
        localStorage.removeItem('user')
        Vue.prototype.$http.defaults.headers.common['Authorization'] = ''
      })
  }
  check() {
    return Vue.prototype.$http
      .get(`/user/check`)
      .then(response => {
        if (!response.data || !response.data.ok) {
          localStorage.removeItem('user')
          Vue.prototype.$http.defaults.headers.common['Authorization'] = ''
        }
        return response.data && response.data.ok
      })
      .catch(() => {
        localStorage.removeItem('user')
        Vue.prototype.$http.defaults.headers.common['Authorization'] = ''
      })
  }
}

export default new AuthService()
